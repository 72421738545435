interface PatientVariable {
  label: string
  value: string
  info: string
  visible: boolean
}

const variables: PatientVariable[] = [
  {
    label: "{{ PATIENT.ID }}",
    value: "{{ PATIENT.ID }}",
    info: "Pass patient unique identifier",
    visible: false
  },
  {
    label: "{{ PATIENT.FIRST_NAME }}",
    value: "{{ PATIENT.FIRST_NAME }}",
    info: "Pass patient first name",
    visible: true
  },
  {
    label: "{{ PATIENT.LAST_NAME }}",
    value: "{{ PATIENT.LAST_NAME }}",
    info: "Pass patient last name",
    visible: true
  },
  {
    label: "{{ PATIENT.ADDRESS_1 }}",
    value: "{{ PATIENT.ADDRESS_1 }}",
    info: "Pass patient street address 1",
    visible: true
  },
  {
    label: "{{ PATIENT.ADDRESS_2 }}",
    value: "{{ PATIENT.ADDRESS_2 }}",
    info: "Pass patient street address 2",
    visible: true
  },
  {
    label: "{{ PATIENT.CITY }}",
    value: "{{ PATIENT.CITY }}",
    info: "Pass patient city",
    visible: true
  },
  {
    label: "{{ PATIENT.STATE }}",
    value: "{{ PATIENT.STATE }}",
    info: "Pass patient state",
    visible: true
  },
  {
    label: "{{ PATIENT.ZIP }}",
    value: "{{ PATIENT.ZIP }}",
    info: "Pass patient ZIP code",
    visible: true
  },
  {
    label: "{{ PATIENT.COUNTRY }}",
    value: "{{ PATIENT.COUNTRY }}",
    info: "Pass patient country",
    visible: true
  },
  {
    label: "{{ PRACTICE.COVERKEY }}",
    value: "{{ PRACTICE.COVERKEY }}",
    info: "Practice identifier",
    visible: false
  },
  {
    label: "{{ PRACTICE.NAME }}",
    value: "{{ PRACTICE.NAME }}",
    info: "Practice name",
    visible: true
  },
  {
    label: "{{ PRACTICE.EMAIL }}",
    value: "{{ PRACTICE.EMAIL }}",
    info: "Practice email address",
    visible: true
  },
  {
    label: "{{ PRACTICE.ADDR1 }}",
    value: "{{ PRACTICE.ADDR1 }}",
    info: "Practice street address 1",
    visible: true
  },
  {
    label: "{{ PRACTICE.ADDR2 }}",
    value: "{{ PRACTICE.ADDR2 }}",
    info: "Practice street address 2",
    visible: true
  },
  {
    label: "{{ PRACTICE.CITY }}",
    value: "{{ PRACTICE.CITY }}",
    info: "Practice city",
    visible: true
  },
  {
    label: "{{ PRACTICE.STATE }}",
    value: "{{ PRACTICE.STATE }}",
    info: "Practice state",
    visible: true
  },
  {
    label: "{{ PRACTICE.ZIP }}",
    value: "{{ PRACTICE.ZIP }}",
    info: "Practice Zip code",
    visible: true
  },
  {
    label: "{{ PRACTICE.PHONE }}",
    value: "{{ PRACTICE.PHONE }}",
    info: "Practice phone number",
    visible: true
  },
  {
    label: "{{ LOCATION.NAME }}",
    value: "{{ LOCATION.NAME }}",
    info: "Location Name",
    visible: true
  },
  {
    label: "{{ LOCATION.EMAIL }}",
    value: "{{ LOCATION.EMAIL }}",
    info: "Location email address",
    visible: true
  },
  {
    label: "{{ LOCATION.ADDR1 }}",
    value: "{{ LOCATION.ADDR1 }}",
    info: "Location street address 1",
    visible: true
  },
  {
    label: "{{ LOCATION.ADDR2 }}",
    value: "{{ LOCATION.ADDR2 }}",
    info: "Location street address 2",
    visible: true
  },
  {
    label: "{{ LOCATION.CITY }}",
    value: "{{ LOCATION.CITY }}",
    info: "Location city",
    visible: true
  },
  {
    label: "{{ LOCATION.STATE }}",
    value: "{{ LOCATION.STATE }}",
    info: "Location state",
    visible: true
  },
  {
    label: "{{ LOCATION.ZIP }}",
    value: "{{ LOCATION.ZIP }}",
    info: "Location Zip code",
    visible: true
  },
  {
    label: "{{ LOCATION.PHONE }}",
    value: "{{ LOCATION.PHONE }}",
    info: "Location phone number",
    visible: true
  },
  {
    label: "{{ PROVIDER.PREFIX }}",
    value: "{{ PROVIDER.PREFIX }}",
    info: "Provider prefix",
    visible: true
  },
  {
    label: "{{ PROVIDER.FIRST_NAME }}",
    value: "{{ PROVIDER.FIRST_NAME }}",
    info: "Provider first name",
    visible: true
  },
  {
    label: "{{ PROVIDER.LAST_NAME }}",
    value: "{{ PROVIDER.LAST_NAME }}",
    info: "Provider last name",
    visible: true
  },
  {
    label: "{{ PROVIDER.DEGREE }}",
    value: "{{ PROVIDER.DEGREE }}",
    info: "Provider degree",
    visible: true
  },
  {
    label: "{{ PROVIDER.EMAIL }}",
    value: "{{ PROVIDER.EMAIL }}",
    info: "Provider email address",
    visible: true
  },
  {
    label: "{{ PROVIDER.PHONE }}",
    value: "{{ PROVIDER.PHONE }}",
    info: "Provider phone number",
    visible: true
  },
]

export default variables