import moment from "moment-timezone"

import variables from "@src/components/patientVariable/variables"

// fonts
const fonts: { label: string, value: string }[] = [
  { label: 'Helvetica', value: "Helvetica, Arial, sans-serif" },
  { label: 'Arial', value: "Arial, Helvetica, sans-serif" },
  { label: 'Arial Black', value: '"Arial Black", Helvetica, sans-serif' },
  { label: 'Georgia', value: "Georgia, serif" },
  { label: 'Impact', value: "Impact, Charcoal, sans-serif" },
  { label: 'Times New Roman', value: '"Times New Roman", Times, serif' },
  { label: 'Trebuchet MS', value: '"Trebuchet MS", Helvetica, sans-serif' },
]

// default font family
const defaultFontFamily = fonts[1].value

export default {
  api_base_url:
    process.env.GATSBY_API_BASE_URL ||
    "https://eblast-dev-dot-tough-zoo-144917.uc.r.appspot.com",
  storage_url: "https://storage.googleapis.com",
  bucket: process.env.GATSBY_BUCKET || "eblast-staging",
  serverTZ: "Europe/London", // UTC
  clientTZ: moment.tz.guess(), // client timezone
  tinyMceApiKey: process.env.GATSBY_TINYMCE_KEY || "v6trdfh2gc6wp7x9j58an7d4qltm9uonm94fxp85ah5oqkua",
  unsplash_app_name: process.env.GATSBY_UNSPLASH_APP_NAME || "eblast",
  tinyeMceConfig: {
    width: 630,
    selector: "textarea#myTextArea" as any,
    forced_root_block_attrs: {
      'data-node': 'text',
      'style': `font-family: ${defaultFontFamily}; font-size: 14px; color:rgb(0,0,0); background-color: rgb(255,255,255);`
    },
    menubar: false,
    toolbar: false,
    plugins: [
      "link template table hr anchor lists advlist noneditable quickbars advcode powerpaste",
      "tinymcespellchecker"
    ],
    quickbars_insert_toolbar: false,
    quickbars_selection_toolbar:
      "bold italic underline removeformat | quicklink | fontselect fontsizeselect",
    font_formats: fonts.map(font => `${font.label}=${font.value}`).join(";"),
    fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
    table_toolbar: "tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
    extended_valid_elements:
      "svg[*],defs[*],pattern[*],desc[*],metadata[*],g[*],mask[*],path[*],line[*],marker[*],rect[*],circle[*],ellipse[*],polygon[*],polyline[*],linearGradient[*],radialGradient[*],stop[*],image[*],view[*],text[*],textPath[*],title[*],tspan[*],glyph[*],symbol[*],switch[*],use[*]",
    object_resizing: true, // table, img are not resizable
    quickbars_image_toolbar: false, // hide quickbar options for image
    fullpage_default_font_family: `${defaultFontFamily};`,
    spellchecker_language: 'en',
    spellchecker_active: true,
    spellchecker_on_load: true,
    spellchecker_whitelist: variables.map(v => v.value.split('.').pop()?.replace(/[^A-Za-z0-9_']/g, "")),
    contextmenu: "spellchecker",
    paste_as_text: true,
    smart_paste: false
  },
  fonts,
}
