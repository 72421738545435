import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from "@apollo/client"
import { RestLink } from "apollo-link-rest"
import { onError } from "apollo-link-error"
import React from "react"
import fetch from "isomorphic-fetch"
import queryString from "query-string"
import { navigate } from "gatsby"

import { setToken, getToken } from "@src/utils/auth"
import config from "@src/config"

// catch errors
const errorLink = onError(
  ({ graphQLErrors, networkError, response, operation }) => {
    console.log("operation", operation)
    console.log("response", response)
    console.log("graphQLErrors", graphQLErrors)
    console.log("networkError", networkError)
    if (graphQLErrors) {
      if (typeof graphQLErrors === "object" && !Array.isArray(graphQLErrors)) {
        Object.values(graphQLErrors).forEach(val =>
          console.log(`[GraphQL error]: Message: ${val}`)
        )
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
      if ("statusCode" in networkError) {
        switch (networkError.statusCode) {
          case 401:
            navigate("/logout?error=401")
            break
        }
      }
    }
  }
)

// add session id to request
const authRestLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: Record<string, any>) => {
    // save session id in local storage
    const parsed = queryString.parse(
      typeof window !== `undefined` ? window.location.search : ""
    )
    if (parsed && parsed.SessionID) {
      setToken(parsed.SessionID as string)
    }

    // add session id to headers
    return {
      headers: {
        ...headers,
        SessionID: getToken(),
      },
    }
  })

  return forward(operation)
})

const formSerializer = (data: { [key: string]: any }, headers: Headers) => {
  const formData = new FormData()
  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key])
    }
  }
  // headers.set("Content-Type", "multipart/form-data")
  headers.set("Accept", "*/*")
  return { body: formData, headers }
}

// Set `RestLink` with your endpoint
// https://www.apollographql.com/docs/react/api/link/apollo-link-rest/#complete-options
const restLink = new RestLink({
  uri: config.api_base_url,
  customFetch: fetch,
  headers: {},
  bodySerializers: {
    formData: formSerializer,
    // fileEncode: (data: any, headers: Headers) => {
    //   const formData = new FormData()
    //   formData.append("file", data, data.name)
    //   headers.set("Accept", "*/*")
    //   return { body: formData, headers }
    // },
  },
})

// Setup your client
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    authRestLink,
    (errorLink as unknown) as ApolloLink,
    restLink,
  ]),
})

interface IProps {
  children: React.ReactElement
}

const RootApolloProvider = ({ children }: IProps): React.ReactElement => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default RootApolloProvider
