const sessionIdKey = "postal-session-id"
const isBrowser = typeof window !== "undefined"

export const setToken = (token: string): void => {
  isBrowser && localStorage.setItem(sessionIdKey, token)
}

export const getToken = (): string | null => {
  return isBrowser ? localStorage.getItem(sessionIdKey) : null
}

export const logout = (): void => {
  isBrowser && localStorage.removeItem(sessionIdKey)
}
